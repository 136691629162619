<template>
    <div>
      <v-app-bar color="primary" flat prominent>
          <template v-slot:prepend>
              <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
              <v-img 
                      width="100" height="70" 
                      :src="require('@/assets/logos/Logo_blanco.png')"
                      class="custom-img"
              >   
              </v-img>  
              <v-btn rounded="0" icon :href="webUrl" target="_blank">
                  <v-tooltip activator="parent" location="bottom" text="Whatsapp" />
                  <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
              <v-btn rounded="0" icon :href="emailLink" target="_blank">
                  <v-tooltip activator="parent" location="bottom" text="Correo Electrónico" />
                  <v-icon>mdi-email-outline</v-icon>
              </v-btn>
          </template>
      </v-app-bar>

      <v-navigation-drawer
          v-model="drawer"
          :location="$vuetify.display.mobile ? 'bottom' : undefined"
          temporary
          style="position: fixed; top: 0; left: 0; width: 50%; height: 100%; z-index: 2000;"
      >
          <v-btn
              icon
              @click="drawer = false"
              style="top: 10px; left: 10px;"
          >
              <v-icon>mdi-close</v-icon>
          </v-btn>
          
          <v-list v-model:opened="open" style="padding-top: 40px;">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :to="item.to"
          >
            <!-- Item sin subsecciones -->
            <v-list-item v-if="!item.subsections">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-icon>
                <v-icon :icon="item.icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>

            <!-- Item con subsecciones -->
            <v-list-group v-else>
              <template v-slot:activator="{ props }">
                <v-list-item v-bind="props">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon :icon="item.icon"></v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>

              <v-list-item
                v-for="(subitem, j) in item.subsections"
                :key="j"
                :to="subitem.to"
              >
                <v-list-item-title>{{ subitem.title }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
  </template>
  
  <script>

  import menuItem from "@/assets/json/menu.json";

  export default {
    data() {
      return {
        open: ['Alimentacion'],
        webUrl: "https://api.whatsapp.com/send?phone=56978080582&text=Hola%20quisiera%20mas%20informacion",
        email: "carlos.rodriguez@wayraspa.com",
        subject: encodeURIComponent("Información sobre Wayra"),
        body: encodeURIComponent("Hola me gustaría saber mas."),
        drawer: false,
        group: null,
      };
    },
    computed:{
        items(){
          return menuItem;
        },
        emailLink() {
            return `mailto:${this.email}?subject=${this.subject}&body=${this.body}`;
        },
    },
    watch: {
      group() {
        this.drawer = false;
      },
    },
  };
  </script>