<template>
    <v-footer color="black">
        <v-row justify="center" no-gutters>
            <v-btn
                v-for="item in items" 
                :key="item" 
                :to="item.to"
                color="white" 
                variant="text"
                class="mx-2"
                rounded="xl"
            >
                {{ item.title }}
                <v-icon :icon="item.icon" ></v-icon>
            </v-btn>
            <v-divider class="mt-2 border-opacity-75" color="urface" ></v-divider>
            <v-col class="text-center mt-4" cols="12">
                {{  new Date().getFullYear() }} - <strong>Wayra</strong>
            </v-col>
        </v-row>
    </v-footer>
</template>
<script>
import menuItem from "@/assets/json/menu.json";

export default{
    data(){
        
    },
    computed:{
        items(){
            return menuItem;
        }
    }

}

</script>