<template>
    <div>
        <v-app-bar  color="primary" flat border="0">
            <template v-slot:prepend>
                    <v-img 
                            width="100" height="70" 
                            :src="require('@/assets/logos/Logo_blanco.png')"
                            class="custom-img"
                    >   
                    </v-img>  
                    <v-btn rounded="0" icon :href="webUrl" target="_blank">
                        <v-tooltip activator="parent" location="bottom" text="Whatsapp" />
                        <v-icon>mdi-whatsapp</v-icon>
                    </v-btn>
                    <v-btn rounded="0" icon :href="emailLink" target="_blank">
                        <v-tooltip activator="parent" location="bottom" text="Correo Electrónico" />
                        <v-icon>mdi-email-outline</v-icon>
                    </v-btn>
            </template>
            <template v-slot:append>
                <v-tabs centered optional>
                    <template v-for="(item, i) in items" :key="i">
                        <v-tab :id="`${item.title}-${i}`" :to="item.to">
                            <span style="margin-right: 4px;">{{ item.title }}</span>
                            <v-icon :icon="item.icon" ></v-icon>
                        </v-tab>
                        <v-menu 
                            v-if="item.subsections"
                            open-on-hover
                            :key="item.title"
                            max-width="350"
                            close-delay="500"
                            offset-y
                            rounded="0"
                            :activator="`#${item.title}-${i}`"
                            transition="slide-x-transition"
                        >
                            <v-card tile>
                                <v-list>
                                    <v-list-item 
                                        v-for="subitem in item.subsections" 
                                        :key="subitem.title" 
                                        :to="subitem.to"
                                    >
                                        <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </template>
                </v-tabs>
            </template>
        </v-app-bar>
    </div>
</template>
<script>

import menuItem from "@/assets/json/menu.json";

export default{
    data(){
        return{
            webUrl: "https://api.whatsapp.com/send?phone=56978080582&text=Hola%20quisiera%20mas%20informacion",
            email: "carlos.rodriguez@wayraspa.com",
            subject: encodeURIComponent("Información sobre Wayra"),
            body: encodeURIComponent("Hola me gustaría saber mas."),
        }
    },
    computed:{
        items(){
            return menuItem;
        },
        emailLink() {
            return `mailto:${this.email}?subject=${this.subject}&body=${this.body}`;
        },
    }
}
</script>
