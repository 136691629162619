import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";


const app = createApp(App).use(router).use(vuetify).use(ElementPlus);


app.mount('#app')
