<template>
  <v-app style="background-color: #f1f1f1;">
    <system-bar-mobile class="navigation-icon" v-if="mobileView  && !isReglamento"  />
    <system-bar v-if="!mobileView  && !isReglamento" />
    <v-main class="mt-8">
      <router-view />
    </v-main>
    <footer-bar v-if="!isReglamento" />
  </v-app>
</template>

<script>
import SystemBar from './components/app/SystemBar.vue';
import SystemBarMobile from './components/app/SystemBarMobile.vue';
import Footer from './components/app/Footer.vue';

export default {
  metaInfo: {
    title: "Wayra",
    titleTemplate: "%s | Wayra",
    htmlAttrs: { lang: "es" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  data(){
    return{
      mobileView: true,
    }
  },
  components:{
    "system-bar": SystemBar,
    "footer-bar": Footer,
    "system-bar-mobile": SystemBarMobile
  },
  computed: {
    theme_current() {
      return this.$vuetify.theme.current.colors;
    },
    route() {
      return this.$route.path;
    },
    isReglamento() {
      return (this.$route.name === "reglamento" || this.$route.name === "reglamento_huespedes");
    },
  },
  methods:{
    handleView(){
      this.mobileView = window.innerWidth <= 990;
    }
  },
  created(){
    this.handleView();
  },
};
</script>
<style>
.titulo {
  font-size: 1.7rem;
  line-height: 2rem;
  font-family: Roboto,sans-serif !important;
}

.text-center{
    text-align: center !important;
}


.titulo {
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  @media (max-width: 600px) {
    .titulo {
      font-size: 1.3rem; /* Reduce el tamaño en dispositivos móviles */
    }
  }


.textTitle{
    font-weight: 400;
    color:rgb(0, 0, 0);
}
.raleway{
    font-family: Raleway, sans-serif;
}
.center-heading {
  text-align: center;
}
.dotDivider{
    border-bottom: 5px dotted black;
}

.navigation-icon{
  padding: 10px 10px 20px;
  margin-right: 10px;
  cursor: pointer;
}

</style>
